<template lang="html">
  <div class="terminationRecord">
    <NavBar name="终止合同记录"></NavBar>
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="date"
            @change="changeDate"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            type="daterange"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select placeholder="请选择提交人" v-model="params.workerId">
            <el-option
              v-for="(item, index) in workList"
              :key="index"
              :label="item.name"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="params.overIsAgree"
            clearable
            placeholder="审批状态"
          >
            <el-option value="10" label="待审批"></el-option>
            <el-option value="20" label="同意"></el-option>
            <el-option value="30" label="拒绝"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton @click="getList">
            <span>搜索</span>
            <template slot="preImage">
              <img src="../../../assets/img/icon/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <div>
        <el-table
          :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
          }"
          :cell-style="{ 'text-align': 'center' }"
          :data="tableData"
        >
          <el-table-column label="提交人" prop="worker_name"></el-table-column>
          <el-table-column label="提交时间" prop="create_at"></el-table-column>
          <el-table-column
            label="终止合同商户"
            prop="contact_name"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="终止合同编码"
            show-overflow-tooltip
            prop="contract_no"
          ></el-table-column>
          <el-table-column label="原因" prop="over_reason"></el-table-column>
          <el-table-column label="凭证" prop="contract_start">
            <template slot-scope="{ row }">
              <pop tips="查看" @myclick="examineVoucher(row)">
                <img
                  class="icon"
                  src="../../../assets/img/icon/chakan.png"
                  alt
                />
              </pop>
            </template>
          </el-table-column>
          <el-table-column label="审批状态" prop="over_is_agree">
            <template slot-scope="{ row }">
              <span>{{
                row.over_is_agree == 10
                  ? "待审核"
                  : row.over_is_agree == 20
                  ? "同意"
                  : "已拒绝"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="审批人"
            prop="agree_worker_name"
          ></el-table-column>
          <el-table-column label="审批时间" prop="agree_time"></el-table-column>
          <el-table-column label="备注" prop="over_remark"></el-table-column>
        </el-table>
      </div>
      <div class="public-page">
        <el-pagination
          :total="total"
          :current-page="1"
          :page-size="10"
          layout="total,prev,pager,next"
          next-text="下一页"
          prev-text="上一页"
          @current-change="getList"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="凭证"
      :visible.sync="vouchervisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-carousel indicator-position="outside" :autoplay="false">
        <el-carousel-item v-for="(item, index) in voucher" :key="index">
          <img
            class="voucher-img"
            :src="$common.hashost(item)"
            fit="cover"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "terminationRecord",
  data() {
    return {
      date: [],
      form: {},
      tableData: [],
      voucher: [],
      workList: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        beginTime: "",
        endTime: "",
        workerId: "",
        overIsAgree: "",
      },
      total: 0,
      vouchervisible: false,
    };
  },
  created() {
    this.getWorkList();
    this.getList();
  },
  methods: {
    getWorkList() {
      this.$request
        .HttpPost("/user/list", {
          sex: -1,
          pageNum: 1,
          pageSize: 99999,
        })
        .then((res) => {
          this.workList = res.data.list || [];
        });
    },
    changeDate(date) {
      if (date) {
        this.params.beginTime = date[0];
        this.params.endTime = date[1];
      } else {
        this.params.beginTime = "";
        this.params.endTime = "";
      }
    },
    examineVoucher(row) {
      if (row.over_voucher) {
        let voucher = JSON.parse(row.over_voucher);
        if (voucher.length < 1) {
          this.$message({ message: "暂无凭证", type: "warning" });
          return false;
        }
        this.voucher = JSON.parse(row.over_voucher);
        this.vouchervisible = true;
      }
    },
    getList() {
      this.$request
        .HttpPost("/contract_agree/list", this.params)
        .then((res) => {
          this.tableData = res.data.list || [];
          this.total = res.data.total;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.public-page {
  margin-top: 20px;
}
/deep/ .el-carousel__container {
  min-height: 300px;
  height: auto;
}
.voucher-img {
  height: 100%;
  object-fit: contain;
}
</style>
